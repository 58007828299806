import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import request from "../../../helpers/request";
import hasPermission from "../../../helpers/permissions";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import './MixedUse.scss'; 
import { useHistory } from 'react-router-dom';
import S3Modal from '../../../components/s3Modal/S3Modal';
import ReactModal from 'react-modal'

export default view(function MixedUseSummary() {
  const history = useHistory();
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("OUTPUT");
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [bulkModel, setBulkModel] = useState('');
  
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    // request(true)
    //   .get(`${endpoints.MIXED_USE_DATES}`)
    //   .then((e) => {
    //     setDates(e.data.dates);
    //     if (e.data.dates.length > 0) setDate(e.data.dates[0].raw);
    //   });
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [filters, selectedGroupBy, date]);

  const getData = () => {
    // if (!date) return;
    setData(null)
    request(true)
      .get(`${endpoints.MIXED_USE_DATA}`, {
        params: {
          // date: date,
          filter: filters,
          group: selectedGroupBy,
        },
      })
      .then((e) => {
        setData(e.data);
      });
  }

  return [
    <GenericPageContainer
      title="Mixed Use - Summary"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <Link className="navigate" to={'/portal/mixed-use/search'}>
            To Search
          </Link>
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      {/* <GenericDataRow title={"File Date"} contentColour={"white"}>
        <select
          onChange={(_) => setDate(_.target.value)}
          value={date}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          {dates.map((item) => {
            return (
              <option key={item.raw} value={item.raw}>
                {item.pretty}
              </option>
            );
          })}
        </select>
      </GenericDataRow> */}
      </div>
      <div className='MU'>
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={selectedGroupBy}
          setSelectectGroupBy={setSelectectGroupBy}
          groupingsEndpoint={`${endpoints.MIXED_USE_GROUPINGS}`}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
        />
        <GenericProductFilterContainer>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={endpoints.MIXED_USE_FILTERS}
          />
          <div className='grid grid-columns-2 grid-gap-5 mta'>
              { (hasPermission('MIXED_USE', 'DOWNLOAD')) ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('download-nhh')}>
                      Download NHH
                  </button>
              : '' }
              { (hasPermission('MIXED_USE', 'DOWNLOAD')) ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('download-hh')}>
                      Download HH
                  </button>
              : '' }
          </div>
      </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal showModal={bulkModel} closeModal={() => setBulkModel('')} modalMode={bulkModel ? 'download' : ''} listingUrl={endpoints.MIXED_USE_FILES + `?mode=${bulkModel.substring(bulkModel.indexOf('-') + 1)}`} uploadUrl={endpoints.MIXED_USE_FILES + `/${bulkModel.substring(bulkModel.indexOf('-') + 1)}`}/>
  ];
})
