import React, { Component } from 'react'
import { view } from '@risingstack/react-easy-state'
import Navigation from '../../../components/navigation/navigation';
import Container from '../../../components/container/container';
import Footer from '../../../components/footer/footer';
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import './voidSalesAlerts.scss';
import { Link, withRouter } from 'react-router-dom';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints';
import { BarChart, XAxis, YAxis, Legend, Bar, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import GenericDataChart from '../../../GenericPageElements/GenericDataChart'
import GenericProductFilterContainer from '../../../GenericPageElements/GenericProductFilterContainer'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import S3Modal from '../../../components/s3Modal/S3Modal';
import session from '../../../stores/session';
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import HelpVideo from '../../../components/help-video/helpVideo';
import useWSProduct from '../../../helpers/useWSProduct';
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter';
import DataGroupings from '../../../components/data-groupings/data-groupings';
import PageTitle from '../../../components/page-header/pageTitle';

const localised = Intl.NumberFormat('en-GB');

export default view(
    withRouter(
        class VoidSaleAlert extends Component {
            constructor(props) {
                super(props);

                this.state = {
                    dataReady: false,
                    dates: [],
                    date: undefined,
                    data: null,
                    graphData: [],
                    group: 'Type',
                    filters: {
                        Deed_Type: null,
                        Consumption: [],
                        New_Connection: null,
                        IMD: [],
                        Voids_Age_Band: [],
                        Occupier_Score: [],
                        Assigned: null,
                    },
                    filterArrays: {
                        IMD: {},
                        Voids_Age_Band: {},
                        Occupier_Score: {},
                        Consumption: {},
                    },
                    files: [],
                    modalIsOpen: false,
                    modalMode: null,
                    modalMessage: null,
                    bulkBuyModalIsOpen: false,
                    bulkAllocateModalIsOpen: false,

                    dataFilters: [],
                    groupings: [],
                }
            }

            getDates = () => {
                return request(true).get(endpoints.VOID_SALES_ALERTS_GET_DATES).then(r => {
                    this.setState({
                        dates: r.data.dates,
                        date: r.data.dates[0].raw
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            getGroupings = () => {
                return request(true).get(endpoints.VOID_SALES_ALERTS_GROUPINGS).then(e => {
                    this.setState({
                        groupings: e.data,
                        group: e.data.find(_ => _.default).target_column
                    })
                })
            }

            setGroup = (field) => {
                this.setState({
                    group: field
                }, () => {
                    this.query();
                });
            }

            setDate = (ev) => {
                this.setState({
                    date: ev.target.value
                }, () => {
                    this.query();
                });
            }

            filter = (field, value) => {
                this.setState({
                    filters: {
                        ...this.state.filters,
                        [field]: value
                    }
                }, () => {
                    this.query();
                });
            }

            filterArray = (field, value) => {
                this.setState({
                    filterArrays: {
                        ...this.state.filterArrays,
                        [field]: {
                            ...this.state.filterArrays[field],
                            [value]: !this.state.filterArrays[field][value]
                        }
                    }
                }, () => {
                    let newFilter = [];

                    Object.keys(this.state.filterArrays[field]).forEach(key => {
                        if (this.state.filterArrays[field][key]) {
                            newFilter.push(key);
                        }
                    });

                    this.setState({
                        filters: {
                            ...this.state.filters,
                            [field]: newFilter
                        }
                    }, () => {
                        this.query();
                    });
                })
            }

            query = () => {
                request(true).get(endpoints.VOID_SALES_ALERTS_SUMMARY, {
                    params: {
                        date: this.state.date,
                        filter: this.state.filters,
                        group: this.state.group
                    }
                }).then(r => {
                    this.setState({
                        data: r.data,
                        dataReady: true
                    });
                });
            }

            clearFilters = () => {
                this.setState({
                    filters: {
                        Type: null,
                        Consumption: [],
                        New_Connection: null,
                        IMD: [],
                        Voids_Age_Band: [],
                        Occupier_Score: [],
                        Assigned: null,
                    },
                    filterArrays: {
                        IMD: {},
                        Voids_Age_Band: {},
                        Occupier_Score: {},
                        Consumption: {},
                    }
                }, () => {
                    this.query();
                });
            }

            componentDidMount() {
                Promise.all([this.getDates(), this.getGroupings()]).finally(() => {
                    this.query();
                })
                useWSProduct('void_sales_alerts');// eslint-disable-line
            }

            openUploadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'upload',
                })
            }

            openDownloadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'download',
                });
            }

            closeModal = () => {
                this.setState({
                    modalIsOpen: false,
                })
            }

            openBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: true
                });
            }

            closeBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: false
                });
            }

            openBulkAllocateModal = () => {
                this.setState({
                    bulkAllocateModalIsOpen: true
                });
            }

            closeBulkAllocateModal = () => {
                this.setState({
                    bulkAllocateModalIsOpen: false
                });
            }

            componentWillUnmount() {
                useWSProduct('void_sales_alerts', 'unlisten');// eslint-disable-line
            }

            render () {
                return (
                    <GenericPageContainer
                        title="Void Sale Alerts" titleRight={
                            <div style={{display: 'flex'}}>
                                { hasPermission('VSA', 'DOWNLOAD') ?
                                    <button className="navigate" onClick={this.openDownloadModal}>Download Return File</button>
                                : null }
                                { hasPermission('VSA', 'UPLOAD') ?
                                    <button className="navigate" onClick={this.openUploadModal}>Issue Input File</button>
                                : null }
                                <Link className="navigate" to="/portal/void-sales-alerts/search">
                                    To Search
                                </Link>
                                { (hasPermission('VSA', 'WORKFLOW')) ?
                                    <Link className="navigate" to="/portal/void-sales-alerts/workflow">
                                        To Workflow
                                    </Link>
                                : null }
                            </div>
                        }
                    >
                        <div className="grid grid-gap-15" id="void-sales-alerts-page">
                            <div className="ov">
                                <div className="data-row file-date">
                                    <div className="data-row-title">
                                        File Date
                                    </div>
                                    <div className="data-row-field">
                                        <select onChange={this.setDate} value={ this.state.date }>
                                            { this.state.dates.map(item => {
                                                return (
                                                    <option key={item.raw} value={item.raw}>
                                                        {item.pretty}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="grid-overview">
                                    <GenericDataChart
                                        dataReady={this.state.dataReady}
                                        data={this.state.data}
                                        selectedGroupBy={this.state.group}
                                        setSelectectGroupBy={this.setGroup}
                                        groupingsEndpoint={`${endpoints.VOID_SALES_ALERTS_GROUPINGS}`}
                                        sizes={{chart: 500, chartContainer: 500, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
                                        xAxisProps={{interval: 0}}
                                        // customBarTick={this.CustomizedAxisTickMultiLine}
                                    />
                                    <GenericProductFilterContainer clearFilters={this.clearFilters} style={{height: 'unset', position: 'sticky', top: '10px'}}>
                                        <ProductDataFilter
                                            filterState={this.state.filters}
                                            applyFilter={this.filter}
                                            dataFilterEndpoint={endpoints.VOID_SALES_ALERTS_DATA_FILTERS}
                                        />
                                        <div className='grid grid-columns-2 grid-gap-5 mta'>
                                        { (hasPermission('VSA', 'BULK-BUY')) ?
                                            <button className="button bulk-buy-button" onClick={this.openBulkBuyModal}>
                                                Bulk Buy
                                            </button>
                                        : '' }
                                        { (hasPermission('VSA', 'ALLOCATE')) ?
                                            <button className="button bulk-buy-button" onClick={this.openBulkAllocateModal}>
                                                Allocate
                                            </button>
                                        : '' }
                                        </div>
                                    </GenericProductFilterContainer>
                                </div>
                            </div>
                        </div>
                        <S3Modal showModal={this.state.modalIsOpen} closeModal={this.closeModal} modalMode={this.state.modalMode} listingUrl={endpoints.VOID_SALES_ALERTS_FILE_UPLOAD} uploadUrl={endpoints.VOID_SALES_ALERTS_FILE_GET}/>
                        <BulkBuy workflowUsersEndpoint={endpoints.VOID_SALES_ALERTS_WORKFLOW_USERS} bulkEndpoint={endpoints.VOID_SALES_ALERTS_SUMMARY_BULK} modalIsOpen={this.state.bulkBuyModalIsOpen} closeModal={this.closeBulkBuyModal} filters={this.state.filters} date={this.state.date}/>
                        <BulkAllocate workflowUsersEndpoint={endpoints.VOID_SALES_ALERTS_WORKFLOW_USERS} bulkEndpoint={endpoints.VOID_SALES_ALERTS_SUMMARY_BULK} modalIsOpen={this.state.bulkAllocateModalIsOpen} closeModal={this.closeBulkAllocateModal} filters={this.state.filters} date={this.state.date}/>
                        <HelpVideo video={session.company.vsa_video_url}/>
                    </GenericPageContainer>
                )
            }
        }
    )
)
