import React, { useCallback, useMemo, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactSelect from 'react-select'
import endpoints from '../../../helpers/endpoints'
import request from '../../../helpers/request'
import { GenericTable } from '../VacantCOTAlerts/sharedComponents'
import Button from '../../../components/large-link-button/largeLinkButton'
import ReactModal from 'react-modal'
import Checkbox from '@material-ui/core/Checkbox';
import './MixedUseManualMatch.scss';
import hasPermission from '../../../helpers/permissions'

export const Summary = ({workflow = false, translations = {}}) => {
  const [chosenPostcode, setChosenPostcode] = useState()
  const [workflowItems, setWorkflowItems] = useState([])
  const [addresses, setAddresses] = useState([])
  const [abpAddresses, setAbpAddresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [selectedABPAddress, setSelectedABPAddress] = useState(null)
  const [ctMode, setCTMode] = useState('unmatched');
  const [customCTPostcode, setCustomCTPostcode] = useState('');
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [noMatchModal, setNoMatchModal] = useState(false);

  const getSummaryData = (workflowInput) => {
    setAbpAddresses([])
    setAddresses([])
    if (chosenPostcode || workflowInput) {
      getABPAddresses(workflowInput)
      getAddresses(workflowInput)
    }
  }

  const getABPAddresses = (workflowInput) => {
    setSelectedABPAddress(null)
    setCTMode('unmatched')
    request(true).get(endpoints.MIXED_USE_MANUAL_MATCH_GET_ABP, {
      params: {
        postcode:  workflowInput || customCTPostcode || chosenPostcode
      }
    }).then(r => {
      setAbpAddresses(r.data);
    }).catch(e => {
      setAbpAddresses([])
    })
  }

  const getAddresses = (workflowInput) => {
    setSelectedAddress(null)

    request(true).get(endpoints.MIXED_USE_MANUAL_MATCH_GET_ADDRESSES, {
      params: {
        postcode: workflowInput || chosenPostcode
      }
    }).then(r => {
      setAddresses(r.data);
    }).catch(e => {
      setAbpAddresses([])
      setAddresses([])
    })
  }

  useEffect(() => {
    setCustomCTPostcode('')
  }, [chosenPostcode])

  useEffect(() => {
    if (workflow) {
      getWorkflowItems()
    }
  }, [])

  useEffect(() =>{
    const addressToWork = addresses.find(address => 
      address.Match_Status === "None_Found" || address.Match_Status === "Multiple_Matches" || address.Match_Status === "Duplicate_String_Found" 
    )
    if (workflow && !addressToWork && addresses.length) {
      getWorkflowItems()
    }
  }, [addresses])

  const getWorkflowItems = () => {
    request(true).get(`${endpoints.MIXED_USE_MANUAL_MATCH_WF_ITEMS}`).then(e => {
      setWorkflowItems(e.data);
      if (e.data.length > 0) {
        setChosenPostcode(_cur && e.data[_cur-1] ? e.data[_cur-1] : e.data[0]);
        getSummaryData(_cur && e.data[_cur-1] ? e.data[_cur-1] : e.data[0]);
      } else {
        setAbpAddresses([])
        setAddresses([])
        setChosenPostcode('')
      }
    })
  }

  const _cur = useMemo(() => {
    return workflowItems.findIndex(_ => _ === chosenPostcode);
  }, [workflowItems, chosenPostcode]);

  const getWorkflowNext = () => {
    if (_cur !== workflowItems.length-1) {
      setChosenPostcode(workflowItems[_cur+1]);
      getSummaryData(workflowItems[_cur+1])
    } else {
      setChosenPostcode(workflowItems[0]);
      getSummaryData(workflowItems[0])
    }
  }

  const getWorkflowLast = () => {
    if (_cur !== 0) {
      setAbpAddresses([])
      setAddresses([])
      setChosenPostcode(workflowItems[_cur-1]);
      getSummaryData(workflowItems[_cur-1])
    } else {
      setAbpAddresses([])
      setAddresses([])
      setChosenPostcode(workflowItems[workflowItems.length - 1]);
      getSummaryData(workflowItems[workflowItems.length - 1])
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getSummaryData()
    }
  }

  const submit = () => {
    request(true).post(endpoints.MIXED_USE_MANUAL_MATCH_SELECT_MATCH, {
      ABP_UPRN: selectedABPAddress,
      CT_REF: selectedAddress
    }).then(r => {
      getSummaryData()
      window.alert("CT matched")
    }).catch(e => {
      window.alert("Server error - failed to submit match")
      console.log(e)
    })
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
      <div style={{display: 'grid', gap: 10}}>
        <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
          <col width={180} />
          <thead>
            <tr>
              <th colSpan={6}>{translations['WSG.Header_Summary']}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width={400}>{translations['WSG.CF_Postcode']}</td>
              <td colSpan={5}>
                <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                  <input style={{border: 'none'}} type="text" onChange={e => setChosenPostcode(e.target.value)} value={chosenPostcode} onKeyDown={handleKeyDown} />
                    { workflow ? 
                      <>
                        <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowLast}></span>
                        <span>{_cur+1}/{workflowItems.length}</span>
                        <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowNext}></span>
                      </>
                    : 
                      <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => getSummaryData()}></span>
                    }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='WSG' style={{gridTemplateColumns: '4fr 2fr 1fr'}}>
        <div  style={{maxHeight: '90vh', overflowY: 'auto', display: 'block'}}>
          <table className='table borders squish smaller-text left' style={{tableLayout: 'auto', width: '100%'}}>
          <thead style={{position: 'sticky', top: '0'}}>
              <tr>
                <td colSpan={2}>{translations['WSG.VOA_Address']}</td>
                <td>Select</td>
                <td colSpan={2}>{translations['WSG.Matched_ABP_Address']}</td>
              </tr>
            </thead>
            <tbody>
            {
                addresses.length > 0 ? 
                  addresses
                  .sort((a, b) => {
                    return a.Match_Status === 'None_Found' || a.Match_Status === 'Multiple_Matches' || a.Match_Status === 'Duplicate_String_Found' ? 1 : b.Match_Status === 'None_Found' || b.Match_Status === 'Multiple_Matches' || b.Match_Status === 'Duplicate_String_Found' ? -1 : 0;
                  })
                  .map(row => {
                    return (
                      <tr style={{height: '60px'}}>
                        <td colSpan={2}>{row.address}</td>
                        <td>
                          {
                            row.Match_Status === "None_Found" || row.Match_Status === "Multiple_Matches" || row.Match_Status === "Duplicate_String_Found" ?
                              <input type="checkbox" name={ row.local_authority_reference_number } onChange={ () => setSelectedAddress( selectedAddress !== row.local_authority_reference_number ? row.local_authority_reference_number : null )} checked={ selectedAddress === row.local_authority_reference_number }/>
                            :
                              null
                          }
                        </td>
                        <td colSpan={2}>
                          { row.Match_Status !== "None_Found" || row.Match_Status !== "Multiple_Matches" || row.Match_Status !== "Duplicate_String_Found" ? 
                            row.AddressBaseAddress
                          : null}
                        </td>
                      </tr>
                    )
                  })
                :
                  <tr>
                    <td colSpan={2}></td>
                    <td></td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
        <div  style={{maxHeight: '90vh', overflowY: 'auto', display: 'block'}}>
          <table className='table borders squish smaller-text left' style={{tableLayout: 'auto', width: '100%'}}>
            <thead style={{position: 'sticky', top: '0'}}>
              <tr>
                <td colSpan={2}>
                  <div style={{display: 'flex', justifyContent: 'center', gap: 10}}>
                    { ctMode === "unmatched" ?
                        translations['WSG.Unmatched_ABP_Address']
                      :
                        translations['WSG.Matched_ABP_Address']
                    } 
                    <div style={{display: 'flex', gap: 10}}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                        <i style={{cursor: 'pointer'}} 
                          class="fa-solid fa-repeat"
                          onClick={() => ctMode === 'unmatched' ? setCTMode('matched') : setCTMode('unmatched')}
                        ></i>
                        <i style={{cursor: 'pointer'}}
                          class="fa-solid fa-search"
                          onClick={() => setPostcodeModal(true)}
                        ></i>
                        { customCTPostcode ? 
                          <i style={{cursor: 'pointer'}}
                            class="fa-solid fa-trash"
                            onClick={() => { setCustomCTPostcode(null); getABPAddresses(chosenPostcode); }}
                          ></i>
                        : ''}
                      </div>
                    </div>
                  </div>
                </td>
                <td>Selected</td>
              </tr>
            </thead>
            <tbody>
              { ctMode === "unmatched" ?
                  abpAddresses.length > 0 ? 
                    abpAddresses.filter(abpAddress =>
                      !addresses
                      .filter(address => {
                        return address.Match_Status !== "None_Found" && address.Match_Status !== "Duplicate_String_Found" && address.Match_Status !== "Multiple_Matches" 
                      })
                      .some(address => 
                        (address.UPRN && address.UPRN == abpAddress.UPRN) && (address.AddressBaseAddress && address.AddressBaseAddress == abpAddress.SINGLE_LINE_ADDRESS)
                      )
                    ).map(row => {
                      return (
                        <tr style={{height: '60px'}}>
                          <td colSpan={2}>{row.SINGLE_LINE_ADDRESS}</td>
                          <td><input type="checkbox" name={ row.UPRN } onChange={ () => setSelectedABPAddress( selectedABPAddress !== row.UPRN ? row.UPRN : null )} checked={ selectedABPAddress === row.UPRN }/></td>
                        </tr>
                      )
                    })
                  :
                    <tr>
                      <td colSpan={2}></td>
                      <td></td>
                    </tr>
              :
                addresses.length > 0 ? 
                  addresses
                  .sort((a, b) => a.Match_Status === 'None_Found' || a.Match_Status === 'Multiple_Matches' || a.Match_Status === 'Duplicate_String_Found'  ? 1 : b.Match_Status === 'None_Found' || b.Match_Status === 'Multiple_Matches' || b.Match_Status === 'Duplicate_String_Found' ? -1 : 0)
                  .filter(address => address.UPRN && address.UPRN !== "None")
                  .map(row => {
                    return (
                      <tr style={{height: '60px'}}>
                        <td colSpan={2}>
                          { row.Match_Status !== "None_Found" && row.Match_Status !== "Multiple_Matches" && row.Match_Status !== "Duplicate_String_Found" ? 
                            row.AddressBaseAddress
                          : null}
                        </td>
                        <td><input type="checkbox" name={ row.UPRN } onChange={ () => setSelectedABPAddress( selectedABPAddress !== row.UPRN ? row.UPRN : null )} checked={ selectedABPAddress === row.UPRN }/></td>
                      </tr>
                    )
                  })
                :
                  <tr>
                    <td colSpan={2}></td>
                    <td></td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
        <div style={{display: 'grid', gap: '10px', height: 'fit-content'}}>
          {
            selectedABPAddress && selectedAddress && hasPermission('WHOLESALER_GAPS', 'PURCHASE') ?
              <button onClick={() => submit()} style={{height: 'fit-content', backgroundColor: 'var(--primary)'}} className='button compact smaller-text colour-white'>Submit</button>
            :
              <button style={{height: 'fit-content'}} disabled className='button compact smaller-text'>Submit</button>
          }
          <button onClick={() => setNoMatchModal(true)} style={{height: 'fit-content'}} disabled={selectedAddress && hasPermission('WHOLESALER_GAPS', 'PURCHASE') ? false : true} className={`button compact smaller-text ${selectedAddress && hasPermission('WHOLESALER_GAPS', 'PURCHASE')  ? 'cancel' : ''}`}>No Match</button>
        </div>
      </div>
      <ReactModal 
      isOpen={noMatchModal} 
      onRequestClose={() => setNoMatchModal(false)}
      className="card bulk-allocate"
      contentLabel="Wholesaler GAPs No Match"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          <h3>No Match Available</h3>
          <p>Please confirm there is no suitable match.</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <button className="button compact smaller-text cancel" onClick={ () => setNoMatchModal(false) }>Cancel</button>
            <button className="button compact smaller-text background-primary colour-white" onClick={() => {
              setNoMatchModal(false); 
              setSelectedABPAddress(null); 
              submit()}}
            >No Match</button> 
          </div>
        </div>
      </ReactModal>
      <ReactModal 
      isOpen={postcodeModal} 
      onRequestClose={() => setPostcodeModal(false)}
      className="card bulk-allocate"
      contentLabel="Wholesaler GAPs postcode"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          <h3>Postcode Search</h3>
          <input value={customCTPostcode} onChange={_ => setCustomCTPostcode(_.target.value.toUpperCase())} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <button className={`button compact smaller-text ${customCTPostcode?.length >= 3 ? 'background-primary colour-white' : ''} `} disabled={customCTPostcode?.length < 3} onClick={() => {getSummaryData(); setPostcodeModal(false)}}>Search</button> 
          </div>
        </div>
      </ReactModal>
    </div>
  )
}
